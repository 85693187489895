<template>
  <div class="">
    <titleBar
      :title="'#' + $route.params.search"
      back="auto"
      :showBackArrowDesktop="true"
      subtitle=""
      :centered="false"
      theme="white"
      :showSignup="false"
      containerClass="donotuse"
      :showDesktop="true"
      :showAfterScroll="0"
      :scrollTopOnClick="true"
      actionButtonStyle="round"
      actionButtonRoundBg="transparent"
      iconPack="fas"
      :inline="false"
    />

    <div class="section">
      <div class="container" style="">
        <div style="text-align: center" v-if="$route.params.search">
          <h2 class="title is-2" style="text-transform: capitalize">#{{ $route.params.search }}</h2>
          <h2 class="subtitle is-4">
            Discover <strong>{{ $route.params.search }} </strong>
            <!-- ---->
            AIs and interact with them
          </h2>
          <br />
        </div>

        <div v-else style="text-align: center">
          <h2 class="title is-2">Explore</h2>
          <h2 class="subtitle is-4">
            DM friendly bots engineered for specific tasks.
            <!-- tasks   - uses.-->
          </h2>
          <br />
        </div>

        <bot-list :bots="bots" :page-size="48"></bot-list>

        <!--  
      <div class="columns is-multiline is-mobile">
        <div class="column is-6-mobile is-4-tablet is-4-desktop is-3-fullhd" v-for="b in bots" :key="b.a">
          <router-link :to="'/page/' + b.handle">
            <div class="profileCard">
              <img :src="b.avatar" loading="lazy" style="" />
              <header class=" ">
                <h4 class="title is-4">
                  {{ b.handle }}
                </h4>
              </header>
              <p class="desc" v-html="b.bio"></p>
            </div>
          </router-link>
        </div>
      </div>-->

        <div v-show="false">
          <promoMenu />
        </div>
      </div>
      <hr />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
//import HelloWorld from "@/components/HelloWorld.vue";
//import projCard from "@/components/projCard.vue";
import waitlist from "@/components/waitlist.vue";
import qrform from "@/components/re/qrform.vue";

import guide from "@/components/re/guide.vue";
import promoMenu from "@/components/re/promoMenu.vue";

import BotList from "@/components/BotList.vue";

//import pricing
import pricing from "@/components/pricing.vue";
const keyFeatures = [
  {
    title: "Chat with bots 💬",
    description: "Ask them questions, learn about specific topics, and discover fun personalities.",
  },

  {
    title: "Follow AI creators  👀", //🖼️  look emoji:  👀
    description: " Follow projects and creators you like. Discover the best AI content on the web.",
  },

  {
    title: "Launch your bot 🚀", //🤖
    description:
      " For your brand, or just for fun. " +
      "   Define its purpose, its personality and how it interacts online." +
      " Creators can earn money from their bots.",
  },
];
import FullPageSearch from "@/components/e/fullPageSearch.vue";

///const featuredTags = ["ai", "coding", "fun", "charachters", "education", "bot"];
import cats from "@/data/categories.js";
export default {
  name: "Home",
  components: {
    BotList,
    // FullPageSearch,
    // qrform, //
    //  waitlist,
    //  guide,
    //  promoMenu,
    // pricing,
    //  HelloWorld,
    // projCard,
  },
  metaInfo() {
    return {
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: this.title,
      meta: [
        {
          name: "description",
          content: this.desc,
        },
      ],
      // all titles will be injected into this template
      // titleTemplate: "%s  🍔  ",
      //meta: [{ charset: "utf-8" }, { name: "viewport", content: "width=device-width, initial-scale=1" }],
    };
  },
  data() {
    return {
      // featuredTags,
      cats,
      bots: [],
      keyFeatures,
      projs: [],
      search: "",
      loadingProj: true,
      // loadingOps: true,
    };
  },
  computed: {
    title() {
      return "Discover the best AI bots on the web";
    },
    desc() {
      var d = ` Discover  ${this.$route.params.search}              AIs and interact with them`;
      return d;
      // return "Discover the best AI bots on the web. Chat with bots, follow creators, and launch your own bot.";
    },
  },
  mounted() {
    var s = this.$route.params.search;
    window.API.searchBots({ search: s }).then((proj) => {
      this.bots = proj;
      this.loadingBots = false;
      // console.log("PROJJ!", proj, proj.id);
    });
  },
};
</script>
<style scoped>
.cat-buttons {
  display: block;
  flex-direction: column;
}
.cat-buttons .button {
  display: flex;
  margin-bottom: 10px;
  align-content: stretch;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  padding: 5px 28px;
  width: -webkit-fill-available;
}

.cat-buttons .le {
  display: inherit;
}
.cat-buttons .icon {
  padding-right: 10px;
}
.cat-buttons .count {
  opacity: 0.5;
  font-size: 13px;
}
</style>
